<template>
  <div class="contents relative">
    <SpinOverlay :active="loader.active" styles="background:#ffffff6e, z-index:9999" type="fixed" />
    <div class="container-fluid">
      <div class="profile-content mb-50">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div class="breadcrumb-main p-3" style="background-color: #f4f5f7 !important">
              <h4 class="text-capitalize breadcrumb-title">
                Uploads
                <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                    <router-link to="/">
                      <span class="la la-home"></span>
                    </router-link>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item" style="color: #9299b8">Questions</span>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item">uploads</span>
                  </li>
                </ul>
              </h4>
            </div>
          </div>
          <div class="col-xl-4 col-xxl-6 col-ssm-12 mb-30">
            <!-- <router-link to="/stat/classes"> -->
            <div class="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between rel-pos">
              <div>
                <div class="overview-content">
                  <h1>{{ stat.total || 0 | humanNumber }}</h1>
                  <p> Total PDF</p>
                </div>
              </div>
              <div class="side-icon2">
                <img src="@/assets/img/custom/ques.gif" width="80" />
              </div>
            </div>
            <!-- </router-link> -->
          </div>
          <div class="col-xl-4 col-xxl-6 col-ssm-12 mb-30">
            
            <div class="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between rel-pos">
              <div>
                <div class="overview-content">
                  <h1>{{ stat.normalized || 0 | humanNumber }}</h1>
                  <p> Normalized Question</p>
                </div>
              </div>
              <div class="side-icon2">
                <img src="@/assets/img/normalize.gif" width="80" />
              </div>
            </div>
            <!-- </router-link> -->
          </div>
          <div class="col-xl-4 col-xxl-6 col-ssm-12 mb-30">
           
            <div class="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between rel-pos">
              <div>
                <div class="overview-content">
                  <h1>{{ stat.claimed || 0 | humanNumber }}</h1>
                  <p> Claimed PDF</p>
                </div>
              </div>
              <div class="side-icon2">
                <img src="@/assets/img/claim.gif" width="80" />
              </div>

            </div>
          </div>
    
          <!-- Data Tables and Certifications Ends -->

          <div class="container-fluid overlay-clipped">
            <LoaderOverlay :active="true" v-if="!asyncLoader && loadingFiles" :zIndex="99" position="absolute"
              style="height: 100%" />
            <no-content v-if="noData && !files.length" />
            <div class="row">
              <div class="col-12" v-if="files.length">
                <div class="
                    userDatatable
                    orderDatatable
                    sellerDatatable
                    global-shadow
                    border
                    mb-30
                    py-30
                    px-sm-30 px-20
                    bg-white
                    radius-xl
                    w-100
                  ">
                  <div class="d-flex mb-3 align-items-center">
                    <div>
                      <span style="font-size:13px; font-weight:bold">Total PDF: <span style="font-size:17px">{{
                          stat.total
                      }}</span></span>

                      <!-- <span class="badge badge-primary br-5 p-2 mr-2">Unapproved {{this.stat.pending}}</span> -->


                    </div>
                    <div class="flex-1"></div>
                    <div>
                      <page-size @sizeChange="sizeChange" />
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table mb-0 table-borderless border-0">
                      <thead>
                        <tr class="userDatatable-header">
                          <th scope="col">
                            <span class="userDatatable-title">No</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Class</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Actions</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Subject</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Normalized <span class="badge badge-info br-5 p-2 mr-2">
                                {{ stat.normalized | humanNumber }}</span></span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Questions <span class="badge badge-info br-5 p-2 mr-2">
                                {{ stat.question | humanNumber }}</span></span>
                          </th>
                          <th scope="col" class="">
                            <span class="userDatatable-title">Claimed By <span class="badge badge-warning br-5 p-2 mr-2"
                                style="color:white">{{ stat.claimed | humanNumber }}</span></span>
                          </th>
                          <th scope="col" class="">
                            <span class="userDatatable-title">Approved By <span
                                class="badge badge-success br-5 p-2 mr-2"> {{ stat.successful |
                                    humanNumber
                                }}</span></span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(file, k) in files" :key="k">
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="d-block mb-0">
                                  {{ paginationData.offset + k + 1 }}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="d-block mb-0">
                                  {{ classFilter(file.cllid) }}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <ul class="
                                orderDatatable_actions
                                mb-0
                                d-flex
                                float-right
                              ">
                              <li>
                                <a :href="
                                  file.asset != '*unclaimed*'
                                    ? `${envVariables.VUE_APP_STORAGE_BUCKET}${file.asset}`
                                    : '#!'
                                " :target="file.asset != '*unclaimed*' ? '_blank' : ''" class="remove"
                                  v-tooltip:bottom="'Download Question Asset'">
                                  <i class="las la-download"></i>
                                </a>
                              </li>
                              <li v-if="file.file != '*unclaimed*'">
                                <a :href="`${envVariables.VUE_APP_STORAGE_BUCKET}${file.file}`" target="_blank"
                                  class="remove" v-tooltip:bottom="'View Question'">
                                  <i class="las la-eye"></i>
                                </a>
                              </li>
                              <li v-else>
                                <a href="" @click.prevent target="_blank" class="remove" v-tooltip:bottom="'No View'">
                                  <i class="las la-eye-slash"></i>
                                </a>
                              </li>
                              <div class="dropdown dropleft mt-2 mx-2">
                                <a href="#" role="button" id="revenue" data-toggle="dropdown" aria-haspopup="true"
                                  aria-expanded="false" v-tooltip:bottom="'Approve/Decline question'">
                                  <i class="las la-ellipsis-v fs-24"></i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="revenue">
                                  <a class="dropdown-item" href="#" @click.prevent="approve(file.nolid, 1)">Approve</a>
                                  <a class="dropdown-item" href="#" @click.prevent="approve(file.nolid, -1)">Decline</a>
                                </div>
                              </div>
                            </ul>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="d-block mb-0">
                                  {{ subjectFilter(file.sulid) }}<br />
                                  <router-link :to="profileFileDetail(file)">{{ getTopicName(file.tolid) }} <br />
                                  </router-link>
                                </p>
                                <p class="mt-2">
                                  Created By <router-link
                                    :to="{ name: 'officers-profile', params: { officer: { ulid: file.actor } } }">{{
                                        getUsername(file.ulid)
                                    }}</router-link> <br />
                                  Uploaded On {{ formattedDate(file.added) }}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="d-block mb-0">
                                  {{ file.normalized }}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="d-block mb-0">{{ file.questions }}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div v-if="file.claim" class="orderDatatable-title">
                              <router-link
                                :to="{ name: 'officers-profile', params: { officer: { ulid: file.actor } } }">{{
                                    getUsername(file.claim)
                                }}</router-link>
                            </div>
                            <div v-else class="orderDatatable-title">
                              <span class="atbd-tag tag-danger tag-transparented">None</span>
                            </div>
                          </td>
                          <td>
                            <div v-if="file.status == 1" class="orderDatatable-title">
                              <router-link
                                :to="{ name: 'officers-profile', params: { officer: { ulid: file.actor } } }">{{
                                    getUsername(file.actor)
                                }}</router-link>
                            </div>
                            <div v-if="file.status == 0" class="orderDatatable-title">
                              <span class="atbd-tag tag-warning tag-transparented">Pending</span>
                            </div>
                            <div v-if="file.status == -1" class="orderDatatable-title">
                              <span class="atbd-tag tag-danger tag-transparented">Declined</span>
                            </div>
                          </td>  
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- ends: col-12 -->
            </div>
          </div>
        </div>
        <Pagination :url="filesUrl" :size="size" @pageChange="pageChange" @loading="loading" />
      </div>
    </div>
    <div class="modal fade new-member" id="upload-file" role="dialog" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content radius-xl">
          <div class="modal-header">
            <h6 class="modal-title fw-500" id="staticBackdropLabel">
              Document Uploads
            </h6>
            <button id="closeDoc" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span><i class="las la-times"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="new-member-modal">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group mb-20">
                    <label for="name47">Select Class</label>
                    <div class="category-member">
                      <v-select v-model="selectedClass" label="nick" :options="allClasses"></v-select>
                      <transition name="slide-fade"><span v-if="
                        (errors && selectedClass == '') ||
                        selectedClass == null
                      " class="text-danger">Select a class.</span></transition>
                    </div>
                  </div>
                </div>
                <transition name="slide-fade">
                  <div v-if="selectedClass" class="col-sm-12">
                    <div class="form-group mb-20">
                      <label for="name47">Select Subject</label>
                      <div class="category-member">
                        <v-select v-model="selectedSubject" label="name" :options="filteredSubjects"></v-select>
                        <transition name="slide-fade"><span v-if="
                          (errors && selectedSubject == '') ||
                          selectedSubject == null
                        " class="text-danger">Select a subject.</span></transition>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div v-if="selectedSubject" class="col-sm-12">
                    <div class="form-group mb-20">
                      <label for="name47">Select Topic</label>
                      <div class="category-member">
                        <v-select v-model="selectedTopic" label="name" :options="[]"></v-select>
                        <transition name="slide-fade"><span v-if="
                          (errors && selectedTopic == '') ||
                          selectedTopic == null
                        " class="text-danger">Select a topic.</span></transition>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div v-if="selectedTopic" class="col-sm-12">
                    <div class="form-group mb-20">
                      <label for="name47">Total Number of Questions</label>
                      <div class="category-member">
                        <input v-model="count" class="form-control" type="text"
                          placeholder="Total number of questions (min. 10)" />
                        <transition name="slide-fade"><span v-if="(errors && count == '') || count == null"
                            class="text-danger">Enter total number of questions.</span></transition>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>

              <div class="add-product__body-img px-sm-40 px-20">
                <form v-if="!doc.base64" v-cloak class="box" @drop.prevent="addFile" @dragover.prevent
                  enctype="multipart/form-data">
                  <span class="upload-product-img px-10 d-block">
                    <span class="file-upload">
                      <span data-feather="upload"></span>
                      <input id="upload" class="file-upload__input" type="file" accept="application/pdf"
                        @change="Document_onFileChanged" name="file-upload" />
                    </span>
                    <span class="pera">Drag and drop a document</span>
                    <span>or
                      <label style="cursor: pointer" for="upload" class="color-secondary">Browse</label>
                      to choose a document</span>
                  </span>
                </form>
                <transition v-if="(errors && doc == '') || doc == null" name="slide-fade"><span
                    class="text-danger">Select a document to upload.</span></transition>
                <div v-if="doc.base64" class="
                    upload-product-media
                    d-flex
                    justify-content-between
                    align-items-center
                    mt-25
                  ">
                  <div class="upload-media-area d-flex">
                    <object v-if="doc.type == 'application/pdf'" width="100" height="80" :data="doc.base64"
                      :type="doc.type">
                      <embed :src="doc.base64" :type="doc.type" />
                    </object>
                    <div class="
                        upload-media-area__title
                        d-flex
                        flex-wrap
                        align-items-center
                        ml-10
                      ">
                      <div>
                        <p>{{ doc.name }}</p>
                        <span>{{ parseInt(doc.size / 1000) }} KB</span>
                      </div>
                      <div class="upload-media-area__btn">
                        <button type="button" class="
                            transparent
                            rounded-circle
                            wh-30
                            border-0
                            color-danger
                          " @click="clear()">
                          <i class="las la-trash-alt fs-24"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="button-group d-flex pt-25">
                <button class="
                    btn btn-primary btn-default btn-squared
                    text-capitalize
                    float-right
                  " @click="addDocument()">
                  Add File
                </button>
                <button class="
                    btn btn-light btn-default btn-squared
                    fw-400
                    text-capitalize
                    float-right
                    b-light
                    color-light
                  " data-dismiss="modal" aria-label="Close">
                  cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ResponseModal ref="resModal" />
    <QuestionSettingsModal :qMeta="qMetaData" message="here is the message" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import NoContent from "@/components/NoContent";
import { authComputed } from "@/vuex/helpers.js";
import moment from "moment";
import { apiClient } from "@/plugins/restclient.js";
import { mapGetters, mapState } from "vuex";
import LoaderOverlay from "@/components/LoaderOverlay";
import SpinOverlay from "@/components/SpinOverlay";
import ResponseModal from "@/components/ResponseModal.vue";
import QuestionSettingsModal from "../../components/QuestionSettingsModal.vue";
import PageSize from '../../components/PageSize.vue';
export default {
  name: "questions-normalisation",
  components: {
    LoaderOverlay,
    ResponseModal,
    Pagination,
    SpinOverlay,
    NoContent,
    QuestionSettingsModal,
    PageSize,
  },
  data() {
    return {
      isLoading: false,
      errors: false,
      totalPage: 0,
      noData: false,
      loadingFiles: false,
      size: 10,
      files: [],
      users: [],
      qMetaData: {},
      loader: {
        active: false,
      },
      selectedClass: "",
      selectedSubject: "",
      selectedTopic: "",
      count: "",
      id: 1,
      cType: "",
      sType: "",
      doc: "",
      docTitle: "",
      statusCode: "",
      searchValue: "",
      headers: "Status",
      filesUrl: "/v1/office/assessment/normal/summon",
      paginationData: "",
      topicsMeta: {},
      stat: {}
    };
  },
  metaInfo: {
    title: "Greater Favour",
    titleTemplate: "%s | Normalisation",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {
    ...authComputed,
    ...mapState(["asyncLoader"]),
    ...mapGetters(["allClasses", "allSubjects", "allTopics"]),
    filteredSubjects() {
      return this.allSubjects.filter((subject) => {
        return this.selectedClass
          ? subject.cllid === this.selectedClass.cllid
          : "";
      });
    },
    filteredClass() {
      return this.allClasses.filter((subject) => {
        return this.selectedClass
          ? subject.cllid === this.selectedClass.cllid
          : "";
      });
    },
    filteredTopics() {
      return this.allTopics.filter((topic) => {
        return this.selectedSubject
          ? topic.sulid === this.selectedSubject.sulid
          : "";
      });
    },
  },
  beforeMount() {
    if (this.loggedIn == false) {
      this.$router.push({ path: "/login" });
    }
    this.getStat()
  },
  methods: {
    profileFileDetail(file) {

      return {
        name: 'questions-normalisation-pull',
        params: {
          data: file,
          meta: {
            username: this.getUsername(file.ulid),
            topic: this.getTopicName(file.tolid),
            claimer: this.getUsername(file.claim),
            approver: this.getUsername(file.actor),
            subject: this.subjectFilter(file.sulid)
          }
        }
      }
    },
    getStat() {
      apiClient.get('/v1/office/assessment/normal/stat').then(res => {
        this.stat = {
          ...res.data.info.stat
        }
      }).catch(() => {

      })
    },
    sizeChange(size) {
      this.size = parseInt(size)

    },
    pageChange(dataSet, paginationData, response) {
      if (response.error) {
        this.noData = true
        return
      }

      this.topicsMeta = response.info.topics
      this.users = response.info.users;
      this.files = dataSet;
      this.paginationData = paginationData;
      if (this.files.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    getUsername(ulid) {
      const data = { ...this.users[ulid] };
      return data.username;
    },
    formattedDate(date) {
      return moment(new Date(date)).format("DD-MM-YYYY");
    },
    loading(status) {
      this.loadingFiles = status;
    },
    getFiles(num) {
      var ts = Math.round(+new Date() / 1000);

      this.isLoading = true;
      const headers = {
        "X-Timestamp": ts,
        "X-Ulid": `${this.$store.state.user.ulid}`,
      };

      let url = "/v1/office/assessment/normal/summon";
      url =
        num != undefined && num != ""
          ? `${url}?offset=${(num - 1) * this.size}&size=${this.size}`
          : url;

      apiClient
        .get(`${url}`, { headers: headers })
        .then((res) => {
          this.isLoading = false;
          this.totalPage = Math.ceil(res.data.info.total / this.size);
          this.files = res.data.data;
        })
        .catch(() => {
          this.isLoading = false;
          this.noData = true
        });
    },
    subjectFilter(sId) {
      const subjectName = this.allSubjects.filter((subject) => {
        return subject.sulid === sId ? subject?.name : "";
      })[0]?.name;
      return subjectName || '-'
    },
    topicFilter() {
      // const topicName =  this.allTopics.filter((topic) => {
      //   return topic.tolid === tolid ? topic.name : "";
      // })[0]?.name;

      return '' //topicName || '-'
    },
    getTopicName(tolid) {
      const topicName = this.topicsMeta[tolid]?.name
      return topicName ? topicName : '-'
    },
    classFilter(cllid) {
      const className = this.allClasses.filter((klass) => {
        return klass.cllid === cllid ? klass.name : "";
      })[0]?.name;

      return className || '-'
    },

    Document_onFileChanged(event) {
      var selectedFile = event.target.files[0];
      if (!selectedFile) return;
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      let baseFile = "";
      reader.onload = () => {
        // <------ use arrow function
        baseFile = reader.result;
        const docs = {
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          lastModifiedDate: event.target.files[0].lastModifiedDate,
          base64: baseFile,
          type: event.target.files[0].type,
        };
        this.$emit("input", docs);
        this.doc = docs;
      };
    },
    addQuestionMeta(cllid, sulid, tolid, topicName) {
      this.qMetaData = {
        cllid,
        sulid,
        tolid,
        tname: topicName,
      };
    },
    addFile(e) {
      let droppedFile = e.dataTransfer.files[0];
      if (!droppedFile) return;
      var reader = new FileReader();
      reader.readAsDataURL(droppedFile);
      let baseFile = "";
      reader.onload = () => {
        // <------ use arrow function
        baseFile = reader.result;
        const docs = {
          name: droppedFile.name,
          size: droppedFile.size,
          lastModifiedDate: droppedFile.lastModifiedDate,
          base64: baseFile,
        };
        this.$emit("input", docs);
        this.doc = docs;
      };
    },
    addDocument() {
      if (!this.doc && !this.docTitle) {
        this.errors = true;
        if (this.doc == null || this.doc == "") {
          this.errors = true;
          if (this.docTitle == "" || this.docTitle == null) {
            this.errors = true;
            if (!this.selectedClass.cllid || !this.selectedSubject.sulid) {
              this.errors = true;
              return;
            }
          }
        }
      } else {
        var ts = Math.round(+new Date() / 1000);

        this.isLoading = true;
        this.loader.active = true;
        const headers = {
          "X-Timestamp": ts,
          "X-Ulid": `${this.$store.state.user.ulid}`,
        };

        const reqBody = {
          cllid: this.selectedClass.cllid,
          sulid: this.selectedSubject.sulid,
          tolid: this.selectedTopic.tolid,
          questions: parseInt(this.count),
          data: this.doc.base64,
        };

        apiClient
          .post(`/v1/office/assessment/normal/add`, reqBody, {
            headers: headers,
          })
          .then((res) => {
            this.isLoading = false;
            this.loader.active = false;
            this.$refs.resModal.open({
              type: "success",
              message: res.data.message,
            });
            this.getFiles();
            setTimeout(() => {
              document.getElementById("closeDoc").click();
            }, 100);
          })
          .catch((err) => {
            this.isLoading = false;
            this.loader.active = false;
            if (err.response) {
              this.$refs.resModal.open({
                type: "error",
                message: err.response?.data.message,
              });
            }
            setTimeout(() => {
              this.erro = false;
            }, 5000);
          });
      }
    },
    claim(nId) {
      var ts = Math.round(+new Date() / 1000);

      this.loader.active = true;
      const headers = {
        "X-Timestamp": ts,
        "X-Ulid": `${this.$store.state.user.ulid}`,
      };

      const reqBody = {
        nolid: nId,
      };

      apiClient
        .patch(`/v1/office/assessment/normal/claim`, reqBody, {
          headers: headers,
        })
        .then((res) => {
          this.getFiles(1);
          this.loader.active = false;
          this.$refs.resModal.open({
            type: "success",
            message: res.data.message,
          });
        })
        .catch((err) => {
          this.loader.active = false;
          if (err.response) {
            this.$refs.resModal.open({
              type: "error",
              message: err.response.data.message,
            });
          }
          setTimeout(() => {
            this.erro = false;
          }, 5000);
        });
    },
    approve(nId, code) {
      var ts = Math.round(+new Date() / 1000);

      this.loader.active = true;
      const headers = {
        "X-Timestamp": ts,
        "X-Ulid": `${this.$store.state.user.ulid}`,
      };

      const reqBody = {
        nolid: nId,
        status: code,
      };
      this.loader.active = true;
      apiClient
        .patch(`/v1/office/assessment/normal/approve`, reqBody, {
          headers: headers,
        })
        .then((res) => {
          this.getFiles(1);
          this.loader.active = false;
          this.$refs.resModal.open({
            type: "success",
            message: res.data.message,
          });
        })
        .catch((err) => {
          this.loader.active = false;
          if (err.response) {
            this.$refs.resModal.open({
              type: "error",
              message: err.response.data.message,
            });
          }
          setTimeout(() => {
            this.erro = false;
          }, 5000);
        });
    },
    clear() {
      this.errors = false;
      this.docTitle = "";
      this.doc = "";
      if (document.getElementById("upload")) {
        document.getElementById("upload").value = "";
      }
    },
  },
};
</script>

<style scoped>
.bread-item:hover {
  color: #9299b8 !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s ease;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.row-hover:hover {
  background-color: #f5f5f5 !important;
}

.tooltip-inner {
  background-color: black !important;
  color: #fff;
}

.tooltip.top .tooltip-arrow {
  border-top-color: black;
}

.tooltip.right .tooltip-arrow {
  border-right-color: black;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: black;
}

.tooltip.left .tooltip-arrow {
  border-left-color: black;
}
</style>
